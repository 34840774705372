
.auth {
    display: none;
}

.dataTables_length {
    margin-bottom: 10px;
}

.dataTables_filter {
    margin-bottom: 15px;
}

.dataTables_wrapper .dataTables_filter input {
    font-weight: normal;
    background-color: #fafafa;
}

table.dataTable {
    margin-bottom: 15px;
    font-size: 12px;
}

#lastUpdated {
    font-size: 12px;
    color: #888;
}
