@use 'bootstrap';

@font-face {
    font-family: 'new-order';
    src: url('assets/New Order Bold.ttf') format("truetype");
}

.logo {
    font-family: 'new-order', sans-serif;
    font-size: 48px;
    color: #0088cc;
}

.offcanvas-panel {
    width: 50% !important;
}

.cb-truncate {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.tooltip {
    max-width: 400px;
}
