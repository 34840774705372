
$body-bg: #fff;
$body-color: #111;

$font-family-base: 'Open Sans', sans-serif;
$font-size-root: 14px;

$navbar-padding-x: 1rem;
$navbar-padding-y: 0.4rem;

$nav-link-font-size: 1rem;
$nav-link-padding-x: 1rem;
$nav-link-padding-y: 0.2rem;

@import '../../../node_modules/bootstrap/scss/bootstrap';
